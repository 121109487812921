import React from "react";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";

const OSs = {
  android: {
    test: /Android/i,
  },
  iOS: {
    test: /iPhone|iPad|iPod/i,
  },
  windows: {
    test: /Windows Phone|IEMobile/i,
  },
};

function getUserAgent() {
  for (const k in OSs) {
    if (navigator.userAgent.match(OSs[k].test)) return k;
  }
  return "";
}

function parseQueryString(qs: string): { [key: string]: string } {
  if (qs[0] === "?") qs = qs.slice(1);
  const params = {};
  qs.split("&").forEach(function (query) {
    const parts = query.split("=");
    params[parts[0]] = parts[1];
  });
  return params;
}

const StudentLoginRedirect = () => {
  useRedirectHandler(() => {
    let webURL = "https://www.classdojo.com/studentstories";

    const query = parseQueryString(location.search);
    const token = query.t;
    const studentId = query.s;

    if (!token) {
      return webURL;
    }

    webURL = "https://student.classdojo.com/#/directLogin?token=" + token + "&studentId=" + studentId;

    const isMobile = getUserAgent();

    const appURL = "classdojo://www.classdojo.com/ul/s/home?s=" + studentId + "&t=" + token;

    if (isMobile) {
      location.replace(appURL);
    }

    return webURL;
  });

  return (
    <>
      <SEO noindex={true} />
      <div>Redirecting...</div>
    </>
  );
};

export default StudentLoginRedirect;
